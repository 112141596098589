var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row flex-center"},[_c('div',{staticClass:"row col-xs-11 col-sm-9 col-md-7 col-lg-7 col-xl-7 q-mb-xl"},[_c('q-card',{staticClass:"login-card col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",staticStyle:{"margin-top":"50px !important","margin-bottom":"50px !important","border-radius":"20px"}},[_c('q-toolbar',{staticStyle:{"background-color":"#E5E5E5"}},[_c('div',{staticClass:"row items-center justify-start full-width"},[_c('q-icon',{staticClass:"cursor-pointer",staticStyle:{"font-size":"35px"},attrs:{"name":"chevron_left","color":"principal"},on:{"click":function($event){return _vm.backAction()}}}),_c('div',{class:'text-center titulo_card row items-center ' +
                (_vm.$q.screen.xs || _vm.$q.screen.sm ? 'q-ml-xs' : 'q-ml-lg')},[_vm._v(" Selección de Servicio ")])],1)]),_c('q-card-section',[_c('div',{staticClass:"row q-pa-md"},[_c('div',{staticClass:"row col-12 descripcion-service"},[_c('div',{staticClass:"col-12 title-descripcion-service"},[_vm._v(" "+_vm._s(_vm.service_selected.nombre || _vm.$store.state.ticket.service.name)+" ")]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"text-font color-white",domProps:{"innerHTML":_vm._s(
                  _vm.service_selected.descripcion ||
                    _vm.$store.state.ticket.service.description
                )}})])]),(
              _vm.service_selected.servicio_url &&
                _vm.service_selected.servicio_url.length > 0
            )?_c('div',{staticClass:"col-12",staticStyle:{"margin-top":"20px","min-height":"280px"}},[_c('q-scroll-area',{staticClass:"q-mb-lg",staticStyle:{"height":"85%"},attrs:{"visible":""}},[_c('q-list',{staticClass:"row rounded-borders q-py-none"},_vm._l((_vm.service_selected.servicio_url),function(link){return _c('div',{key:link.id,staticClass:"row justify-center col-12"},[_c('div',{staticClass:"col-12 col-sm-7"},[_c('q-item',{staticClass:"text-left"},[_c('q-item-section',[_c('q-item-label',{staticClass:"title-service-card"},[_vm._v(" "+_vm._s(link.nombre)+" ")]),_c('q-item-label',{staticClass:"description-service"},[_c('div',{staticClass:"text-font",domProps:{"innerHTML":_vm._s(link.descripcion)}})])],1)],1)],1),_c('div',{staticClass:"row justify-center items-center col-11 col-sm-5"},[(link.tipo == 1)?_c('q-btn',{staticClass:"btn full-width btn-aux",staticStyle:{"text-transform":"none"},attrs:{"label":_vm.$t('forms.action'),"type":"a","target":"_blank","href":link.url}}):(link.tipo == 0)?_c('q-btn',{staticClass:"btn full-width btn-aux",staticStyle:{"text-transform":"none"},attrs:{"label":_vm.$t('forms.more_information'),"type":"a","target":"_blank","href":link.url}}):_c('q-btn',{staticClass:"btn full-width btn-aux",staticStyle:{"text-transform":"none"},attrs:{"label":_vm.$t('forms.schedule'),"type":"a","target":"_blank","href":link.url}})],1),_c('div',{staticClass:"col-12",staticStyle:{"margin":"15px 0px"}},[_c('q-separator',{attrs:{"inset":""}})],1)])}),0)],1)],1):_vm._e(),_c('div',{staticClass:"row col-12",class:{ 'btn-margin': _vm.active }},[_c('q-btn',{staticClass:"btn full-width",attrs:{"color":"principal","label":"Continuar solicitud"},on:{"click":function($event){return _vm.stepTwoSubmit()}}})],1)])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }