
import { Component, Mixins, Ref } from "vue-property-decorator";
import DateHelpers from "@/mixins/DateHelpers";
import FormValidator from "@/mixins/FormValidator";
// import comunas from "@/helpers/comunas.json";
import { Money } from "v-money";
import { QForm } from "quasar";

@Component({
  components: {
    Money
  }
})
export default class CreatePostulationOmil extends Mixins(
  DateHelpers,
  FormValidator
) {
  @Ref("postulationForm") form!: QForm;

  giros: Array<any> = [];
  options_giros: Array<any> = [];
  options_jornadas: Array<any> = [];
  options_documentacion: Array<any> = [];
  options_district: Array<any> = [];
  options_district_offer: Array<any> = [];

  protected formData: any = {
    rut: "",
    entidad: "1",
    razon_social: "",
    giro: null,
    comuna_empresa: 1,
    direccion: "",
    n_trabajadores: "1",
    nombre_contacto: "",
    telefono_contacto: "",
    email_contacto: "",
    cargo: "",
    puestos: 0,
    descripcion: "",
    fecha_inicio: "",
    genre: "",
    edad_min: 18,
    edad_max: 75,
    adulto_mayor: false,
    discapacidad: false,
    inmigrantes: false,
    diversidad: false,
    comuna_oferta: 1,
    sueldo: "",
    horario: "",
    experiencia: false,
    observaciones: "",
    jornadas: [],
    documentacion: []
  };

  block_submit = false;
  isPerson = false;

  loaded_giros = false;
  loaded_documentacion = false;
  loaded_jornadas = false;

  formattedFecha: string | null = "";
  filter_string: string = "";
  private fechaHoy: any = new Date();

  my_locale = {
    days: "Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado".split("_"),
    daysShort: "Dom_Lun_Mar_Mié_Jue_Vie_Sáb".split("_"),
    months: "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
      "_"
    ),
    monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_"),
    firstDayOfWeek: 7
  };

  moneyFormatForComponent = {
    decimal: ",",
    thousands: ".",
    //prefix: '$ ',
    //suffix: ' #',
    precision: 0,
    masked: true
  };

  options_trabajadores: object[] = [
    { label: "Menos de 100", value: "1" },
    { label: "Más de 100", value: "2" }
  ];

  options_entidad: object[] = [
    { label: "Empresa", value: "1" },
    { label: "Persona", value: "2" }
  ];

  options_genre = [
    { label: "Mujer", value: "1" },
    { label: "Hombre", value: "2" },
    { label: "Sin preferencia", value: "4" }
  ];

  toolbar = [
    [
      {
        label: "Align",
        icon: this.$q.iconSet.editor.align,
        fixedLabel: true,
        options: ["left", "center", "right", "justify"]
      }
    ],
    ["bold", "italic", "strike", "underline"],
    [
      {
        label: "Fuente",
        icon: this.$q.iconSet.editor.font,
        list: "no-icons",
        options: ["Principal"]
      }
    ],
    [
      {
        label: this.$q.lang.editor.fontSize,
        icon: this.$q.iconSet.editor.fontSize,
        fixedLabel: true,
        fixedIcon: true,
        list: "no-icons",
        options: ["size-1", "size-2", "size-3", "size-4", "size-5"]
      }
    ]
  ];

  fonts = { Principal: "Font-principal" };

  private notify(message: string) {
    this.$q.notify({
      message: message,
      color: "principal",
      position: "top",
      timeout: 5000
    });
  }

  private validarFecha(date: any) {
    if (!/^\d{2}-\d{2}-\d{4}$/.test(date)) {
      return "Fecha incompleta. Por favor, ingrese una fecha en el formato dd-mm-aaaa";
    } else if (!this.checkDate(date)) {
      return "Fecha inválida. Por favor, escriba una fecha válida";
    }
    return "";
  }

  clearPerson() {
    this.formData = {
      rut: "",
      razon_social: "",
      entidad: "",
      giro: null,
      comuna_empresa: "",
      direccion: "",
      n_trabajadores: "1",
      nombre_contacto: "",
      telefono_contacto: "",
      email_contacto: "",
      cargo: "",
      puestos: 0,
      descripcion: "",
      fecha_inicio: "",
      genre: "",
      edad_min: 18,
      edad_max: 75,
      adulto_mayor: false,
      discapacidad: false,
      inmigrantes: false,
      diversidad: false,
      comuna_oferta: "",
      sueldo: "",
      horario: "",
      experiencia: false,
      observaciones: "",
      jornadas: [],
      documentacion: []
    };
  }

  clearStreet(): void {
    this.formData.street = "";
  }

  mounted() {
    this.getComunas();
    this.getGiros();
    this.getJornadas();
    this.getDocumentacion();
  }

  private comunas: Array<any> = [];

  private getComunas() {
    this.$axios
      .get("comunas/listar")
      .then(response => {
        this.comunas = response.data;
      })
      .catch(error => {})
      .finally(() => {});
  }

  entityChange(entity: string) {
    this.isPerson = entity == "1" ? false : true;
    this.formData.razon_social = "";
    this.formData.giro = "";
    this.formData.n_trabajadores = "";
  }

  filterDistrict(val: string, update: any) {
    if (val === "") {
      update(() => {
        this.options_district = this.comunas;
      });
      return;
    }

    update(() => {
      const value = val.toLowerCase();
      this.options_district = (this.comunas as any).filter((comuna: any) => {
        return comuna.label.toLowerCase().indexOf(value) > -1;
      });
    });
  }

  filterDistrictOffer(val: string, update: any) {
    if (val === "") {
      update(() => {
        this.options_district_offer = this.comunas;
      });
      return;
    }

    update(() => {
      const value = val.toLowerCase();
      this.options_district_offer = (this.comunas as any).filter(
        (comuna: any) => {
          return comuna.label.toLowerCase().indexOf(value) > -1;
        }
      );
    });
  }

  abortFilterFn() {
    this.options_district = this.comunas;
  }

  limitUntil(date: any) {
    return Date.parse(date as any) >= Date.parse(this.fechaHoy as any);
  }

  limitEdadMin(input: number) {
    return (
      input < this.formData.edad_max ||
      "La edad indicada no puede ser mayor a la máxima"
    );
  }

  limitEdadMax(input: number) {
    return (
      input > this.formData.edad_min ||
      "La edad indicada no puede ser menor a la mínima"
    );
  }

  limitEdad(input: number) {
    return (input >= 18 && input <= 75) || "La edad indicada es inválida";
  }

  boldCoincidence(str: string, query: string): string {
    const n = str.toUpperCase();
    const q = query.toUpperCase();
    const x = n.indexOf(q);
    if (!q || x === -1) {
      return str; // bail early
    }
    return (
      str.substr(0, x) +
      "<b>" +
      str.substr(x, q.length) +
      "</b>" +
      str.substr(x + q.length)
    );
  }

  public isRutForm(value: string) {
    var texto = value;
    if (texto.length > 12 || texto.length < 8) {
      return "Ingrese un rut válido";
    }
    var check_letters = texto.match(/([A-Za-z])/g);
    if (check_letters && check_letters.length >= 2) {
      return "El rut no puede tener 2 letras";
    }
    var tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    var largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    var invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    var dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    var cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return this.$t("validation.invalid_rut");
  }

  protected formatRutForm() {
    if (this.formData.rut.replace("-", "").replace(/[.-]/g, "").length <= 7) {
      this.formData.rut = this.formData.rut
        .replace("-", "")
        .replace(/[.-]/g, "")
        .replace(/^(\d{3})(\d{3})(\w{1})$/, "$1.$2-$3");
    } else {
      this.formData.rut = this.formData.rut
        .replace("-", "")
        .replace(/[.-]/g, "")
        .replace(/^(\d{0,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
    }
  }

  filterGiro(val: string, update: any) {
    this.filter_string = val;
    if (val == null) {
      return;
    }
    if (val === "") {
      update(() => {
        this.options_giros = this.giros;
      });
      return;
    }

    update(() => {
      const value = val.toLowerCase();
      this.options_giros = this.giros.filter((offer: any) => {
        let filtered = offer.nombre.toLowerCase().indexOf(value) > -1;
        return filtered;
      });
    });
  }

  private getGiros() {
    this.$axios
      .get("empresa-giros/listar", {
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.options_giros = response.data;
        this.giros = response.data;
        this.loaded_giros = true;
      })
      .catch(error => {})
      .finally(() => {
        this.loaded_giros = true;
      });
  }

  private getJornadas() {
    this.$axios
      .get("empresa-jornadas", {
        params: {
          filter: {
            where: { estado: 1 }
          }
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.options_jornadas = response.data;
      })
      .catch(error => {})
      .finally(() => {
        this.loaded_jornadas = true;
      });
  }

  private getDocumentacion() {
    this.$axios
      .get("empresa-documentacions", {
        params: {
          filter: {
            where: { estado: 1 }
          }
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.options_documentacion = response.data;
      })
      .catch(error => {})
      .finally(() => {
        this.loaded_documentacion = true;
      });
  }

  onSubmit() {
    this.form.validate().then(response => {
      if (response) this.postulationAttemp();
    });
  }

  postulationAttemp() {
    this.$q.loading.show();
    (this.block_submit = true),
      this.$axios
        .post("empresa-postulacions/postular", {
          rut: this.formData.rut,
          entidad: Number(this.formData.entidad),
          razon_social: this.isPerson ? "" : this.formData.razon_social,
          id_empresa_giro: this.isPerson ? -1 : this.formData.giro,
          id_comuna: this.formData.comuna_empresa,
          direccion: this.formData.direccion,
          trabajadores: this.isPerson
            ? 1
            : Number(this.formData.n_trabajadores),
          nombre_contacto: this.formData.nombre_contacto,
          telefono_contacto: this.formData.telefono_contacto,
          email_contacto: this.formData.email_contacto,
          cargo: this.formData.cargo,
          puestos: Number(this.formData.puestos),
          fecha_inicio: this.birthDate(this.formData.fecha_inicio),
          descripcion: this.formData.descripcion,
          genero: Number(this.formData.genre),
          edad_min: Number(this.formData.edad_min),
          edad_max: Number(this.formData.edad_max),
          adulto_mayor: this.formData.adulto_mayor ? 1 : 0,
          discapacidad: this.formData.discapacidad ? 1 : 0,
          inmigrantes: this.formData.inmigrantes ? 1 : 0,
          diversidad: this.formData.diversidad ? 1 : 0,
          id_comuna_oferta: Number(this.formData.comuna_oferta),
          sueldo: this.formData.sueldo.replace(".", ""),
          horario: this.formData.horario,
          experiencia: this.formData.experiencia ? 1 : 0,
          observaciones: this.formData.observaciones,
          jornadas: this.formData.jornadas,
          documentacion: this.formData.documentacion
        })
        .then(response => {
          this.notify("Registro finalizado exitosamente");
          this.$router.push({ name: "newrequest" });
        })
        .catch(error => {
          this.notify("Hubo un problema registrando a la empresa");
        })
        .finally(() => {
          this.block_submit = false;
          this.$q.loading.hide();
        });
  }
}
