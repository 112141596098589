
import Login from "@/views/Login.vue";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class DialogService extends Vue {
  @Prop({ default: 0 }) readonly service_selected!: any;
  dialog = false;
  motive_selected: any = {};
  active: Boolean = true;
  private mounted() {
    this.$gtag.event("Servicio: " + this.service_selected.nombre, {
      event_label: "Confirmar Seleccionar servicio",
      event_category: "test",
      value: 1
    });
    if (
      this.service_selected.servicio_url &&
      this.service_selected.servicio_url.length > 0
    ) {
      this.active = false;
    }
  }
  private askMoreHelp() {
    this.$emit("moreHelp", true);
    this.dialog = false;
  }

  private cerrar() {
    this.$emit("vaciarInput", false);
  }

  private stepTwoSubmit() {
    this.$store.dispatch("setStateToTwo");
    // if (Object.keys(this.service_selected).length) {
    //   let ticket: any = {
    //     step: 1,
    //     service_id: this.service_selected.id,
    //     service_name: this.service_selected.nombre,
    //     motive: Number(this.motive_selected.value),
    //   };

    //   this.$store.dispatch("submitRequestStepTwo", ticket);
    // } else {
    //   this.$q.notify({
    //     message: this.$t("notification.no_service_selected") as string,
    //     color: "principal",
    //     position: "top",
    //     timeout: 5000,
    //   });
    // }
  }

  private backAction() {
    this.$router.go(0);
  }
}
