var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row col-12",staticStyle:{"margin-bottom":"70px"}},[(this.finish_loading == false)?_c('div',{staticClass:"row col-12"},[_c('q-card',{staticClass:"bg-aviso"},[_c('q-card-section',[_c('div',{staticClass:"row col-12"},[_c('div',{staticClass:"col-12 text-center"},[_vm._v(" No tiene postulaciones realizadas ")])])])],1)],1):_vm._e(),(this.finish_loading == true)?_c('div',{staticClass:"col-12"},[_c('q-scroll-area',{staticStyle:{"height":"480px"}},_vm._l((_vm.postulations),function(item){return _c('q-card',{key:item.id,staticClass:"q-my-lg cursor-pointer",staticStyle:{"border-radius":"15px"},on:{"click":function($event){return _vm.$router.push({
            name: 'postulationdetails',
            params: { id_postulacion: item.id }
          })}}},[_c('q-toolbar',{staticClass:"bg-card-tickets"},[_c('div',{staticClass:"row justify-between full-width items-center"},[_c('div',{staticClass:"q-py-md items-center row q-pl-sm"},[_c('label',{staticStyle:{"font-family":"'Font-principal Medium'"}},[_vm._v("Oferta: ")]),_c('label',{staticClass:"text-ticket q-ml-sm"},[_vm._v(_vm._s(item.oferta.nombre)+" ("+_vm._s(item.oferta.codigo)+") ")])]),(item.estado_postulacion == 1)?_c('q-card',{staticClass:"btn-ticket-list"},[_c('q-card-section',{staticClass:"q-pa-sm padding-mobile",style:({
                  'background-color': _vm.getPostulationStateColor(
                    item.postulacion_historial
                      ? item.postulacion_historial[0]
                      : item
                  )
                })},[_c('label',{staticClass:"row justify-center text-white",staticStyle:{"font-family":"'Font-principal Medium'"}},[_vm._v(" "+_vm._s(item.postulacion_historial ? item.postulacion_historial[0].postulacion_estado .nombre : item.postulacion_estado.nombre)+" ")])]),(item.estado_postulacion == 1)?_c('q-tooltip',{attrs:{"offset":[10, 10]}},[_c('label',{staticStyle:{"font-size":"14px !important"}},[_vm._v(" "+_vm._s(_vm.$t('labels.my_postulations_list.state_helper'))+" ")])]):_vm._e()],1):_c('q-card',{staticClass:"btn-ticket-list"},[_c('q-card-section',{staticClass:"q-pa-sm padding-mobile",style:({
                  'background-color': _vm.getPostulationStateColor(
                    item.postulacion_historial
                      ? item.postulacion_historial[0]
                      : item
                  )
                })},[_c('label',{staticClass:"row justify-center text-white",staticStyle:{"font-family":"'Font-principal Medium'"}},[_vm._v(" "+_vm._s(item.postulacion_historial ? item.postulacion_historial[0].postulacion_estado .nombre : item.postulacion_estado.nombre)+" ")])]),(item.estado_postulacion == 2)?_c('q-tooltip',{attrs:{"offset":[10, 10]}},[_c('label',{staticStyle:{"font-size":"14px !important"}},[_vm._v(" "+_vm._s(_vm.$t('labels.my_postulation_detail.waiting_state_two_helper'))+" ")])]):_vm._e(),(item.estado_postulacion == 13)?_c('q-tooltip',{attrs:{"offset":[10, 10]}},[_c('label',{staticStyle:{"font-size":"14px !important"}},[_vm._v(" "+_vm._s(_vm.$t('labels.my_postulation_detail.waiting_state_thirteen_helper'))+" ")])]):_vm._e(),(item.estado_postulacion == 6)?_c('q-tooltip',{attrs:{"offset":[10, 10]}},[_c('label',{staticStyle:{"font-size":"14px !important"}},[_vm._v(" "+_vm._s(_vm.$t('labels.my_postulation_detail.waiting_state_six_helper'))+" ")])]):_vm._e()],1)],1)]),_c('q-card-section',[_c('div',{staticClass:"row justify-between col-12"},[_c('div',{staticClass:"row col-11"},[_c('div',{staticClass:"col-12 text-card-tickets"},[_vm._v(" "+_vm._s(item.oferta.empresa.nombre)+" ")]),_c('div',{staticClass:"col-12 text-bold",staticStyle:{"font-family":"'Font-principal'"}},[_vm._v(" "+_vm._s(_vm.humanDate(item.fecha))+" ")]),_c('div',{staticClass:"col-12",staticStyle:{"font-family":"'Font-principal'","margin-top":"10px"},domProps:{"innerHTML":_vm._s(item.oferta.descripcion)}})]),_c('div',{staticClass:"row justify-end items-center col-1"},[_c('q-icon',{staticClass:"cursor-pointer",staticStyle:{"font-size":"35px"},attrs:{"name":"chevron_right","color":"principal"},on:{"click":function($event){return _vm.$router.push({
                    name: 'postulationdetails',
                    params: { id_postulacion: item.id }
                  })}}})],1)])])],1)}),1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }