var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.finish_loading && _vm.tickets.length > 0)?_c('q-table',{staticClass:"full-width q-mb-md",staticStyle:{"border-radius":"15px"},attrs:{"grid":_vm.$q.screen.lt.sm,"table-header-class":'table-header',"data":_vm.tickets,"columns":_vm.columns,"rows-per-page-label":'Resultados por página',"pagination-label":_vm.getPaginationLabel,"row-key":"name","color":"amber","separator":"vertical"},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('q-tr',[_c('q-td',{key:"ticket",attrs:{"props":props}},[_vm._v(_vm._s(props.row.correlativo ? props.row.correlativo : props.row.id))]),_c('q-td',{key:"date",attrs:{"props":props}},[_vm._v(_vm._s(_vm.humanDate(props.row.fecha)))]),_c('q-td',{key:"service",attrs:{"props":props}},[_vm._v(" "+_vm._s(props.row.servicio_nombre)+" ")]),_c('q-td',{key:"status",class:_vm.getTextColor(props.row.estado_ticket),attrs:{"props":props}},[_vm._v(_vm._s(_vm.getStatusName(props.row.estado_ticket)))]),_c('q-td',{staticClass:"text-center"},[_c('q-btn',{staticClass:"q-ma-sm text-bold",attrs:{"color":_vm.getBtnColor(props.row.estado_ticket)},on:{"click":function($event){return _vm.$router.push({
                name: 'requestdetailsnew',
                params: { id_ticket: props.row.correlativo }
              })}}},[_vm._v("Ver")])],1)],1)]}},{key:"item",fn:function(props){return [_c('q-card',{staticClass:"flex full-width q-my-md",attrs:{"props":props}},[_c('q-card-section',{staticClass:"full-width"},[_c('div',{staticClass:"item-name"},[_vm._v("Ticket:")]),_c('div',{staticClass:"item-description"},[_vm._v(_vm._s(props.row.id))])]),_c('q-card-section',{staticClass:"full-width"},[_c('div',{staticClass:"item-name"},[_vm._v("Fecha:")]),_c('div',{staticClass:"item-description"},[_vm._v(_vm._s(_vm.humanDate(props.row.fecha)))])]),_c('q-card-section',{staticClass:"full-width"},[_c('div',{staticClass:"item-name"},[_vm._v("Estado:")]),_c('div',{staticClass:"item-description",class:_vm.getTextColor(props.row.estado_ticket)},[_vm._v(" "+_vm._s(_vm.getStatusName(props.row.estado_ticket))+" ")])]),_c('q-card-section',{staticClass:"full-width"},[_c('div',{staticClass:"item-name"},[_vm._v("Detalle:")]),_c('q-btn',{staticClass:"q-mb-sm full-width",attrs:{"rounded":"","color":_vm.getBtnColor(props.row.estado_ticket)},on:{"click":function($event){return _vm.$router.push({
                name: 'requestdetailsnew',
                params: { id_ticket: props.row.correlativo }
              })}}},[_vm._v("Ver")])],1)],1)]}}],null,false,550864709)}):_vm._e(),(_vm.tickets.length == 0 && _vm.finish_loading == false)?_c('div',{staticClass:"row justify-center col-12 q-my-md"},[_c('div',{staticClass:"col-12 col-md-8"},[_c('q-card',{staticClass:"my-card",staticStyle:{"border-radius":"20px"}},[_c('q-card-section',{staticClass:"q-pb-none"},[_c('div',{staticClass:"row justify-center col-12 text-center"},[_c('h4',{staticClass:"text",staticStyle:{"font-family":"Font-principal Medium","color":"grey"}},[_vm._v(" No se han encontrado solicitudes. ")])])]),_c('q-card-section',[_c('div',{staticClass:"row justify-center col-12"},[_c('div',{staticClass:"row justify-center col-md-8 col-sm-10 col-12"},[_c('q-btn',{attrs:{"rounded":"","icon":"add_circle_outline","color":"principal","label":"Crear Solicitud"},on:{"click":function($event){return _vm.$router.push({ name: 'newrequest' })}}})],1)])])],1)],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }